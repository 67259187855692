import axios from 'axios';

export const analyticsIdentifier = (errorId?: string, errorHash?: string) => {
  if (!errorHash && !errorId) return 'No error identifier';
  return errorHash
    ? `error hash: ${errorHash}` // From backend
    : `error ID: ${errorId}`; // Generated by frontend to make it possible to triage user reported errors.
};

export const extractMessageFromAxiosError = (err: Error) => {
  // message does not exist on the data field for Axios Error.Response, but we add it
  // in our API for custom messages
  if (axios.isAxiosError(err)) {
    if (err.response?.data?.message) {
      return err.response?.data?.message as string;
    }
    if ((err.response?.data ?? [])[0]?.message) {
      return err.response?.data[0]?.message as string;
    }
    if (err.response?.data) {
      return err.response?.data as string;
    }
  }
};
